[class^="#{$prefix}"], [class*=" #{$prefix}"] {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}

@each $icon, $unicode in $icons {
  $icon-lower: to-lower-case(#{$icon});
  .#{$prefix}#{$icon-lower} {
    background-image: url(../svg/flag/#{$prefix}#{$icon}.svg);
  }
}
